import { NextPage } from 'next'
import { WithHead } from 'components/compositions'
import { ErrorView } from 'components/organisms'

const Custom404: NextPage = () => {
  return (
    <>
      <WithHead title="ページが見つかりません" />
      <ErrorView title="404 | ページが見つかりません" />
    </>
  )
}

export default Custom404
